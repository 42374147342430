














































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import "../decs.ts";
// eslint-disable-next-line no-unused-vars
import { ThumbnailImageData } from "../models/ImageData";
// eslint-disable-next-line no-unused-vars
import { Photo } from "@/models/Photo";
import { Mutex } from "async-mutex";
import { getAsBase64 } from "@/services/photo.service";

@Component
export default class Thumbnails extends Vue {
  // Props
  @Prop() private thumbnailsData!: Array<Photo>;
  @Prop() private thumbnailsHeightPrefixSum!: Array<number>;
  @Prop() private imageIndex!: number;

  // Watch
  @Watch("thumbnailsHeightPrefixSum")
  async thumnailsHeightPrefixSumHandler() {
    this.contentHeight = this.thumbnailsHeightPrefixSum[
      this.thumbnailsHeightPrefixSum.length - 1
    ];
    this.firstDisplayedImageIndex = Math.max(
      this.findImageIndex(-this.contentLayerPosition) - 1,
      0
    );
    this.lastDisplayedImageIndex = Math.min(
      this.findImageIndex(-this.contentLayerPosition + this.stageHeight) + 1,
      this.thumbnailsData.length - 1
    );
    this.currentImages = await this.loadImagesInRange(
      this.firstDisplayedImageIndex,
      this.lastDisplayedImageIndex
    );
    this.selectedRectangle = {
      position: this.currentImages[0].y,
      width: this.currentImages[0].image.width,
      height: this.currentImages[0].image.height
    };
  }

  @Watch("imageIndex")
  imageIndexHandler(): void {
    let currentIndex = this.imageIndex - this.firstDisplayedImageIndex;
    if (
      this.thumbnailsHeightPrefixSum[this.imageIndex] >=
        -this.contentLayerPosition &&
      this.thumbnailsHeightPrefixSum[this.imageIndex + 1] <=
        -this.contentLayerPosition + this.stageHeight
    ) {
      if (currentIndex >= 0 && currentIndex < this.currentImages.length) {
        this.handleThumbnailClick(this.currentImages[currentIndex]);
      }
    } else {
      const delta =
        this.thumbnailsHeightPrefixSum[this.imageIndex] /
        (this.contentHeight - this.stageHeight);
      const availableHeight =
        this.stageHeight - this.scrollbar.padding * 2 - this.scrollbar.height;
      const position = delta * availableHeight + this.scrollbar.padding;
      this.handleScroll(
        this.scrollbarBoundFunc({
          x: 0,
          y: position
        }).y
      ).then(() => {
        currentIndex = this.imageIndex - this.firstDisplayedImageIndex;
        this.selectedRectangle = {
          position: this.currentImages[currentIndex].y,
          width: this.currentImages[currentIndex].image.width,
          height: this.currentImages[currentIndex].image.height
        };
      });
    }
  }

  // Data
  stageHeight = window.innerHeight * 0.8;
  stageWidth = 150;
  contentHeight = 0;
  scrollbar = {
    width: 10,
    height: 100,
    padding: 5
  };
  currentScrollbarPosition = this.scrollbar.padding;
  selectedRectangle = {
    position: 0,
    width: 0,
    height: 0
  };
  contentLayerPosition = 0;
  currentImages = new Array<ThumbnailImageData>();
  firstDisplayedImageIndex = 0;
  lastDisplayedImageIndex = 0;
  // informs us if all current thumbnails are loaded
  // during loading scrolling is locked
  finishedLoading = true;
  mutex = new Mutex();

  // Methods
  private async loadImagesInRange(start: number, end: number) {
    let thumbnails: Array<ThumbnailImageData> = [];
    for (let i = start; i <= end; i++) {
      const photo = this.thumbnailsData[i];
      const imageLoadPromise = new Promise(resolve => {
        const img = new window.Image();
        const base64 = getAsBase64(photo.imageSrc);

        base64.then(base64Src => {
          if (typeof base64Src === "string") {
            img.src = base64Src;
            img.onload = () => {
              thumbnails.push(
                new ThumbnailImageData(
                  img,
                  photo.id,
                  0,
                  this.thumbnailsHeightPrefixSum[i]
                )
              );
              resolve();
            };
          }
        });
      });
      await imageLoadPromise;
    }
    return thumbnails;
  }

  handleThumbnailClick(imageInfo: ThumbnailImageData) {
    const index = this.currentImages.indexOf(imageInfo);
    this.$emit("thumbnailClicked", this.firstDisplayedImageIndex + index);

    this.selectedRectangle = {
      position: imageInfo.y,
      width: imageInfo.image.width,
      height: imageInfo.image.height
    };
  }

  scrollbarBoundFunc(pos: any) {
    pos.x = this.stageWidth - this.scrollbar.padding - 10;

    // lock scrollbar if we are still loading images
    if (!this.finishedLoading) {
      pos.y = this.currentScrollbarPosition;
      return pos;
    }

    pos.y = Math.max(
      Math.min(
        pos.y,
        this.stageHeight - this.scrollbar.height - this.scrollbar.padding
      ),
      this.scrollbar.padding
    );
    return pos;
  }

  private findImageIndex(position: number) {
    let start = 0;
    let end = this.thumbnailsHeightPrefixSum.length - 1;
    while (start <= end) {
      let mid = Math.floor((start + end) / 2);
      if (this.thumbnailsHeightPrefixSum[mid] < position) {
        start = mid + 1;
      } else {
        end = mid - 1;
      }
    }
    return start;
  }

  private async handleScroll(scrollbarPosition: number) {
    this.finishedLoading = false;
    this.currentScrollbarPosition = scrollbarPosition;
    const availableHeight =
      this.stageHeight - this.scrollbar.padding * 2 - this.scrollbar.height;
    let delta =
      (this.currentScrollbarPosition - this.scrollbar.padding) /
      availableHeight;
    this.contentLayerPosition =
      -(this.contentHeight - this.stageHeight) * delta;

    // handle dynamic thumbnails loading
    let newFirstDisplayedImageIndex = Math.max(
      this.findImageIndex(-this.contentLayerPosition) - 1,
      0
    );
    let newLastDisplayedImageIndex = Math.min(
      this.findImageIndex(-this.contentLayerPosition + this.stageHeight) + 1,
      this.thumbnailsData.length - 1
    );

    // number of new images to laod
    let count = 0;
    // if we want to add images at the top
    await this.mutex.runExclusive(async () => {
      if (newFirstDisplayedImageIndex < this.firstDisplayedImageIndex) {
        count = this.firstDisplayedImageIndex - newFirstDisplayedImageIndex;
        if (
          count <
          this.lastDisplayedImageIndex - this.firstDisplayedImageIndex + 3
        ) {
          const newImages = await this.loadImagesInRange(
            newFirstDisplayedImageIndex,
            this.firstDisplayedImageIndex - 1
          );
          this.firstDisplayedImageIndex = newFirstDisplayedImageIndex;
          this.currentImages.splice(
            this.currentImages.length - count,
            this.currentImages.length
          );
          for (let i = newImages.length - 1; i >= 0; i--) {
            this.currentImages.unshift(newImages[i]);
          }
          this.lastDisplayedImageIndex -= count;
        }
        // if we want to add images at the bottom
      } else if (newLastDisplayedImageIndex > this.lastDisplayedImageIndex) {
        count =
          newLastDisplayedImageIndex - (this.lastDisplayedImageIndex + 1) + 1;
        if (
          count <
          this.lastDisplayedImageIndex - this.firstDisplayedImageIndex + 1
        ) {
          const newImages = await this.loadImagesInRange(
            this.lastDisplayedImageIndex + 1,
            newLastDisplayedImageIndex
          );
          this.currentImages.splice(0, count);
          for (let i = 0; i < newImages.length; i++) {
            this.currentImages.push(newImages[i]);
          }
          this.firstDisplayedImageIndex += count;
          this.lastDisplayedImageIndex = newLastDisplayedImageIndex;
        }
      }
      if (
        count >=
        this.lastDisplayedImageIndex - this.firstDisplayedImageIndex + 1
      ) {
        this.finishedLoading = false;
        this.currentImages = await this.loadImagesInRange(
          newFirstDisplayedImageIndex,
          newLastDisplayedImageIndex
        );
        this.firstDisplayedImageIndex = newFirstDisplayedImageIndex;
        this.lastDisplayedImageIndex = newLastDisplayedImageIndex;
        this.finishedLoading = true;
      }
    });
    this.finishedLoading = true;
  }

  handleDrag(event: any) {
    this.handleScroll(event.target.attrs.y);
  }

  handleWheel(event: any) {
    event.evt.preventDefault();
    const delta =
      (Math.sign(event.evt.deltaY) * 150) / this.thumbnailsData.length;
    this.handleScroll(
      this.scrollbarBoundFunc({
        x: 0,
        y: this.currentScrollbarPosition + delta
      }).y
    );
  }
}
