var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-stage',{attrs:{"config":{
    width: _vm.stageWidth,
    height: _vm.stageHeight
  }},on:{"wheel":function($event){return _vm.handleWheel($event)}}},[_c('v-layer',{attrs:{"config":{ y: _vm.contentLayerPosition }}},[_vm._l((_vm.currentImages),function(imageInfo){return _c('v-image',{key:imageInfo.id,attrs:{"config":{
        image: imageInfo.image,
        y: imageInfo.y
      }},on:{"click":function($event){return _vm.handleThumbnailClick(imageInfo)}}})}),_c('v-rect',{attrs:{"config":{
        y: _vm.selectedRectangle.position,
        height: _vm.selectedRectangle.height,
        width: _vm.selectedRectangle.width,
        fill: 'blue',
        opacity: 0.3
      }}})],2),_c('v-layer',[_c('v-rect',{attrs:{"config":{
        width: _vm.scrollbar.width,
        height: _vm.scrollbar.height,
        fill: 'grey',
        opacity: 0.8,
        x: _vm.stageWidth - _vm.scrollbar.padding - 10,
        y: _vm.currentScrollbarPosition,
        draggable: true,
        dragBoundFunc: _vm.scrollbarBoundFunc
      }},on:{"dragmove":function($event){return _vm.handleDrag($event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }